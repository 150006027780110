import React from 'react';
import './Features.css'
import { features } from '../../Data';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination,Navigation,Autoplay } from 'swiper/modules';
const Feauters = () => {
  return (
    <section className='features' id='features '>
        <div className="section-title">
         <h1><u>Features For You</u></h1>
        </div>
      
      <Swiper  spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="container">
        {features.map(({ image, title, description,subheading,subcontent }, index) => {
          return (
            <SwiperSlide className='features-holder'>
            <div className='abc'>

            <div className='features-img-wrapper'>
              <img src={image} alt='' className='features_img'/>
            </div>

            <div className='features-content'>
              <h2 className='features-title'>{title}</h2>
              <p className='features-description'>{description}</p>
              <h3>{subheading}</h3>
              <p>{subcontent}</p>
            </div>

            </div>

            
            </SwiperSlide>

          )
        })}

      </Swiper>
    </section>

  )
}

export default Feauters