import React from 'react';
import './Refund.css'
import Navbartwo from '../Navbartwo';

function RefundPolicy() {
  return (
    <>
    <Navbartwo/>
    <div className="refund-policy-container">
    <div className='xyz'>
    <h1>Refund Policy</h1>
    </div>
    <div className='refundcontent'>
    <div className='contents' style={{width:'90%', height:"100%" , margin:"auto"}}>
    <p>
        At EaseSeat, we strive to ensure a seamless experience for our users when booking appointments at spas and salons. This policy outlines the guidelines for refunds and returns for appointments made through our platform. Please read this policy carefully before booking an appointment.
    </p>
    <h2 style={{color:"black"}}>1. Refunds for Bookings:</h2>
    <p>
        <strong>Quality Concerns:</strong> If you receive a service that does not meet the quality standards expected, please contact the spa or salon directly. EaseSeat acts as a platform to facilitate bookings and does not assume responsibility for the quality of the services provided.
    </p>
    <p>
        <strong>Booking Errors:</strong> Any discrepancies in the booking, such as incorrect services or timing, must be reported immediately to the spa or salon. Refunds or replacements will be at the discretion of the spa or salon.
    </p>
    <p>
        <strong>Cancellation by Spa/Salon:</strong> In rare cases where the spa or salon cancels a booking due to unforeseen circumstances or unavailability of staff, a full refund will be issued to the user.
    </p>
    <h2 style={{color:"black"}}>2. Cancellation by Users:</h2>
    <p>
        <strong>Cancellation Policy:</strong> Users may cancel their bookings in accordance with the cancellation policy set by the respective spa or salon. Please review the specific cancellation policy of the spa or salon before booking.
    </p>
    <h2 style={{color:"black"}}  >3. Returns and Refunds via EaseSeat:</h2>
    <p>
        <strong>Non-Receipt of Service:</strong> If you do not receive the service within a reasonable timeframe, please contact the spa or salon directly. EaseSeat will not process refunds for non-receipt of services.
    </p>
    <p>
        <strong>Technical Issues:</strong> In the event of technical issues during payment, if you are charged but the booking is not confirmed, kindly reach out to EaseSeat's customer support for assistance in resolving the matter.
    </p>
    <h2 style={{color:"black"}}>4. Contact Information:</h2>
    <p>
        For any queries, concerns, or assistance regarding your booking, please contact the spa or salon directly using the provided contact details on the app. EaseSeat's customer support is available to assist with technical issues related to the app or payment.
    </p>
    <h2 style={{color:"black"}}>5. Important Notes:</h2>
    <p>
        This policy is subject to change at any time without prior notice. We encourage you to review this policy periodically for any updates.
    </p>
    <p>
        EaseSeat reserves the right to refuse a refund at its sole discretion in cases of fraudulent or abusive behavior.
    </p>
    <p>
        This policy is in addition to the terms and conditions of the EaseSeat app.
    </p>
    </div>
    </div>
</div>
</>
  );
}

export default RefundPolicy;