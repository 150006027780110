import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-scroll';
import { animateScroll } from 'react-scroll';
import { FaStream } from 'react-icons/fa'; // Ensure correct import

import { assets } from '../../Assets/Assets';
import { links } from '../../Data';

const Navbar = () => {
  const [scrollHeader, setScrollHeader] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const changeHeader = () => {
    if (window.scrollY >= 80) {
      setScrollHeader(true);
    } else {
      setScrollHeader(false);
    }
  };

  const scrollTop = () => {
    animateScroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener('scroll', changeHeader);
    return () => {
      window.removeEventListener('scroll', changeHeader);
    };
  }, []);

  return (
    <header className={`${scrollHeader ? 'scroll-header' : ''} header`}>
      <nav className='nav container'>
        <Link to='/' onClick={scrollTop} className='nav__logo'>
          <img src={assets.logo} alt='Logo' className='nav__logo-img' />
        </Link>

        <div className={`${showMenu ? 'show-menu' : ''} nav-menu`}>
          <ul className='nav-list'>
            {links.map(({ name, path }, index) => (
              <li className='nav_item' key={index}>
                <Link
                  spy={true}
                  smooth={true}
                  offset={-60}
                  hashSpy={true}
                  duration={500}
                  to={path}
                  className='nav-link'>
                  {name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className='nav-toggle' onClick={() => setShowMenu(!showMenu)}>
          <FaStream />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
