import React from 'react';
import './About.css';
import { about } from '../../Data';
import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { assets } from '../../Assets/Assets';


const About = () => {
  return (
    <section className='about ' id='about'>
      <div className='section-title'>
        <h2><u>About Us</u></h2>
      </div>
      <div className='about-container'>
        <div className='about-img-wrapper'>
          <img src={assets.aboutone} alt='' className='about_img' />
        </div>
        <Swiper spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="about-content-wrapper" >
          {about.map(({ title, description }, index) => {
            return (
              <SwiperSlide className='about-item' key={index} >
                <div className='about-content' >
                  <h3 className='about-title' style={{ color: 'black' }}>{title}</h3>
                  <p className='about-description' style={{
                    color: 'black', fontSize: '17px', textAlign:"justify"
                  }}>{description}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}

export default About;