import React from 'react';
// import './Refund.css'
import Navbartwo from '../Navbartwo';

function Privacy() {
    return (
        <>
            <Navbartwo />
            <div className="refund-policy-container">
                <div className='xyz'>
                    <h1>Privacy Policy</h1>
                </div>
                <div className='refundcontent'>
                    <div className='contents' style={{ width: '90%', height: "100%", margin: "auto" }}>
                        <p>
                            EaseSeat is dedicated to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you visit our website and use our app. It also explains your rights concerning your personal data.
                        </p>
                        <h2 style={{ color: "black" }}>1. Information We Collect:</h2>
                        <p>
                            <strong>We collect various types of information from you, including:</strong><br/> <strong>Personal Data:</strong> When you register on our app, we may ask for details such as your name, email address, mailing address, phone number, payment information, and other personal information necessary to provide our services.
                        </p>
                        <p>
                            <strong>Usage Data:</strong> We automatically collect information about your interactions with our app, including device information, IP addresses, browser type, operating system, and usage patterns.
                        </p>
                        <p>
                            <strong>Location Data:</strong>With your consent, we may collect and process location data from your device to provide location-based services.
                        </p>

                        <h2 style={{ color: "black" }}>2. How We Use Your Information</h2>
                        <p>
                            <strong>We use the information we collect for the following purposes:</strong> <br/> To personalize and improve your experience on our app.<br />
                            To enhance our app and services based on your feedback and usage patterns.<br />
                            To process your transactions securely and send you related information, including confirmations and receipts.<br />
                            To send you promotional communications, such as newsletters, special offers, and updates, with your consent.<br />
                            To respond to your inquiries, provide customer support, and fulfill your requests.<br />
                            To analyze app usage and trends, ensuring continuous improvement of the user experience.<br />
                            To comply with legal obligations and protect our rights and the rights of others.<br />
                        </p>
                        <h2 style={{ color: "black" }}  >3.Sharing Your Information:</h2>
                        <p>
                            <strong>We do not sell, trade, or rent your personal information to others. We may share your information with:</strong> <br/>
                          <strong>  Trusted Third Parties:</strong> These may include service providers who assist us in operating our app, processing payments, conducting business, or providing services to you. These parties are contractually obligated to keep your information confidential and secure.<br/>
                        <strong>Legal Requirements:</strong> We may disclose your information if required by law, such as in response to a subpoena, court order, or other legal process, or to protect our rights or the safety of others.<br/>
                        <strong>Business Transfers:</strong>In the event of a merger, acquisition, or sale of assets, your information may be transferred to the successor entity.
                        </p>

                        <h2 style={{ color: "black" }}>4. Data Security:</h2>
                        <p>
                            We implement a variety of security measures to protect your personal information, including encryption, firewalls, and secure server technology. Despite our efforts, please be aware that no method of data transmission over the Internet or electronic storage is 100% secure. We cannot guarantee the absolute security of your data but will notify you of any data breaches in accordance with applicable laws.

                        </p>
                        <h2 style={{ color: "black" }}>5. Data Retention:</h2>
                        <p>
                            We will retain your information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. Once your information is no longer needed, we will securely delete or anonymize it.
                        </p>
                        <h2 style={{ color: "black" }}>6. Your Rights</h2>
                        <p>
                            <strong>Depending on your location, you may have the following rights regarding your personal data:
                            </strong><br/>The right to access, correct, or delete your personal information.<br />
                            The right to object to or restrict certain processing activities.<br />
                            The right to withdraw your consent at any time.<br />
                            The right to data portability, where applicable.<br />
                            To exercise your rights, please contact us at the details provided below. We will respond to your request in accordance with applicable data protection laws.
                        </p>
                        <h2 style={{ color: "black" }}>7. Changes to This Privacy Policy</h2>
                        <p>
                            We may update this Privacy Policy from time to time. Any changes will be posted on this page, will be updated accordingly. We encourage you to review this policy periodically to stay informed about how we are protecting your information. Your continued use of the app after any changes constitute your acceptance of the revised policy.
                        </p>
                        <h2 style={{ color: "black" }}>8. Contact Us</h2>
                        <p>
                            <strong>If you have any questions about this Privacy Policy or our data practices, please contact us at:</strong><br/>
                            EaseSeat Support<br/>
                            support@easeseat.com
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Privacy;