import React, { useState } from 'react';
import Navbartwo from './Navbartwo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Delete = () => {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    reason: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    toast.success('Data submitted successfully!');
  };

  return (
    <>
      <Navbartwo />
      <div className="refund-policy-container" >
      <div className='xyz' style={{display:"flex",flexDirection:"column"}}>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <h1>Delete Account</h1>
        </div>
        <div className='refundcontent' style={{width:"60%"}}>
        <div className='conte' style={{width:'90%', height:"100%" , margin:"auto"}}>
        <form onSubmit={handleSubmit}>
              <div style={{ marginRight: '20px' }}>
                <label htmlFor="phoneNumber" style={{ display: 'block', marginBottom: '8px',left:"5" }}>
                  Phone Number:
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                />
              </div>
              <div style={{ marginBottom: '20px', textAlign: 'left' }}>
                <label htmlFor="reason" style={{ display: 'block', marginBottom: '8px' }}>
                  Reason for Deletion:
                </label>
                <select
                  id="reason"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                  }}
                >
                  <option value="">Owner</option>
                  <option value="privacy">Customer</option>
                  <option value="no_longer_needed">Professional</option>
                  {/* <option value="other">Other</option> */}
                </select>
              </div>
              <div style={{ textAlign: 'center' }}>
                <button
                  type="submit"
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
        </div>
        </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Delete;
