// src/components/Footer/Footer.js
import React from 'react';
import './Footer.css';
import { assets } from '../../Assets/Assets';
import { FaRegEnvelopeOpen, FaPhone, FaFacebook, FaLinkedin, FaInstagram, FaCaretRight, FaLocationArrow } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-grid container grid'>
                <div className='footer-content'>
                    <a href='/' className='footer-logo'>
                        <img src={assets.logo} alt='logo' className='footer-logo-img' />
                    </a>

                    <p className='footer-description' style={{ textAlign: 'justify' }}>
                        At EaseSeat, we revolutionize spa and salon management with cutting-edge solutions designed to enhance the experience for both owners and customers. Our state-of-the-art platform seamlessly connects spa owners with elite professionals, streamlining operations and maximizing efficiency. We are committed to delivering excellence, ensuring that every visit to your spa or salon is nothing short of exceptional. Join us and discover the future of spa and salon management today.
                    </p>
                    <ul className='footer-contact'>
                        <li className='footer-contact-item'>
                            <FaPhone className='icon' /> +91 9332316718
                        </li>

                        <li className='footer-contact-item'>
                            <FaRegEnvelopeOpen className='icon' /> support@easeseat.com
                        </li>

                        <li className='footer-contact-item'>
                            <FaLocationArrow className='icon' /> Madhyapara, Ayas, Birbhum, West Bengal, India, PIN - 731243
                        </li>
                    </ul>
                </div>
                <div className='footer-content'>
                    <h3 className='footer-title'>Quick Links</h3>
                    <ul className='footer-links'>
                        <li>
                            <a href='#about' className='footer-link'>
                                <FaCaretRight className='icon' /> About Us
                            </a>
                        </li>

                        <li>
                            <a href='#features' className='footer-link'>
                                <FaCaretRight className='icon' /> Features
                            </a>
                        </li>

                        <li>
                            <a href='#contact' className='footer-link'>
                                <FaCaretRight className='icon' /> Contact
                            </a>
                        </li>

                        <li>
                            <a href='#download' className='footer-link'>
                                <FaCaretRight className='icon' /> Download App
                            </a>
                        </li>

                        <li>
                            <Link to="/refund" className='footer-link' target='_blank' rel='noopener noreferrer'>
                                <FaCaretRight className='icon' /> Refund Policy
                            </Link>
                        </li>

                        <li>
                            <Link to="/privacy" className='footer-link' target='_blank' rel='noopener noreferrer'>
                                <FaCaretRight className='icon' /> Privacy Policy
                            </Link>
                        </li>

                        <li>
                            <Link to="/terms" className='footer-link' target='_blank' >
                                <FaCaretRight className='icon' /> Terms & Conditions
                            </Link>
                        </li>
                        <li>
                            <Link to="/delete-account" className='footer-link' target='_blank' >
                                <FaCaretRight className='icon' /> Delete Account
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='footer-content'>
                    <h3 className='footer-title'>Follow Us</h3>
                    <p className='footer-description'>Follow our Newsletter To Get Latest Update And News</p>
                    <form action='' className='subscribe-form'>
                        <input type='text' placeholder='Enter Your Email' className='subscribe-input form-input' />
                        <button className='subscribe-button btn btn-flex'>Subscribe</button>
                    </form>
                    <div className='footer-socials'>
                        <h3 className='footer-socials-follow'>Follow Us</h3>
                        <div className='footer-socials-link'>
                            <a href='https://www.facebook.com/share/J7Afh9L49UHwiF9A/?mibextid=qi2Omg'
                                className='footer-social-link'
                                target="_blank"  // This opens the link in a new tab
                                rel="noopener noreferrer"  // Security measure for opening new tabs
                            >
                                <FaFacebook className='icon' />
                            </a>
                            <a
                                href='https://www.linkedin.com/company/105116657/admin/dashboard/'
                                className='footer-social-link'
                                target="_blank"  // This opens the link in a new tab
                                rel="noopener noreferrer"  // Security measure for opening new tabs
                            >
                                <FaLinkedin className='icon' />
                            </a>

                            <a href='https://www.instagram.com/easeseat_official/'
                                className='footer-social-link'
                                target="_blank"  // This opens the link in a new tab
                                rel="noopener noreferrer"  // Security measure for opening new tabs
                            >
                               <FaInstagram className='icon' />

                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <p className='copyright-text'>
                © Copyright 2024 <span>EaseSeat</span> All Rights Reserved.
            </p>
        </footer>
    );
};

export default Footer;
