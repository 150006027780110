import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const goToBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const listenToScroll = () => {
    let heightToHidden = 40;
    const winScroll = 
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  const topBtnStyle = {
    fontSize: "2.4rem",
    width: "3rem",
    height: "3rem",
    color: "#fff",
    backgroundColor: "#333", 
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "50%",
    position: "fixed",
    bottom: "5rem",
    right: "5rem",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  };

  const iconStyle = {
    animation: "gototop 1.2s linear infinite alternate-reverse",
  };

  return (
    <div>
      {isVisible && (
        <div className="top-btn" style={topBtnStyle} onClick={goToBtn}>
          <FaArrowUp className="top-btn--icon" style={iconStyle} />
        </div>
      )}
    </div>
  );
};

export default GoToTop;
