import logo from './logo.png'
import home from './home.jpg'
import about from './about.jpg'
import app_store from './app_store.png'
import play_store from './play_store.png'
import facebook_icon from './facebook_icon.png'
import linkedin_icon from './linkedin_icon.png'
import twitter_icon from './twitter_icon.png'
import one from './one.jpeg'
import two from './two.jpeg'
import three from './three.jpeg'
import four from './four.jpeg'
import five from './five.jpeg'
import w1 from './w1.png'
import w5 from './w5.png'
import es6 from './es6.jpg'
import conta from './conta.png'
import home2 from './home2.png'
import aboutone from './aboutone.png'
import uni from './uni.png'
import home3 from './home3.png'
import abouttwo from './abouttwo.jpg'
import unitwo from './unitwo.jpg'
import uni4 from './uni4.jpg'
import uni6 from './uni6.jpg'
import Uni8 from './Uni8.jpg'
import wl from './wl.jpg'
import abc from './abc.png'
import def from './def.png'
import ref from './ref.jpg'
import term from './term.jpg'
import seaone from './seaone.png'
import seatwo from './seatwo.png'
import seathree from './seathree.png'
export const assets = {
    logo,
    home,
    about,
    app_store,
    play_store,
    facebook_icon,
    linkedin_icon,
    twitter_icon,
    one,
    two,
    three,
    four,
    five,
    w1,
    w5,
    es6,
    conta,
    home2,
    aboutone,
    uni,
    home3,
    abouttwo,
    unitwo,
    uni4,
    uni6,
    Uni8,
    wl,
    abc,
    def,
    ref,
    term,
    seaone,
    seatwo,
    seathree

}