import React, { useState } from 'react';
import './Contact.css';
import { assets } from '../../Assets/Assets';
import axios from 'axios';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [message, setMessage] = useState('');
  

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(name, email, message, number);
        const data={
            Name:name,
            Email:email,
            ContactNo:number,
            Message:message,

        }
        axios.post('https://sheet.best/api/sheets/276dfd35-8a4f-494c-92ac-9711773251c2',data).then((response)=>{
            console.log(response);
            setName('');
            setEmail('');
            setMessage('');
            setNumber('');
        })
    }

    return (
        <section className='contact container' id='contact'>
            <h2 className='section-title' style={{color:'black'}}>
                Register Your Spa & Salon
            </h2>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <form className='contact-form-grid' style={{ width: '90%' }} onSubmit={handleSubmit}>
                    <div className='form-input-div'>
                        <input
                            type='text'
                            placeholder='Your Name'
                            className='form-input'
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                        />
                    </div>

                    <div className='form-input-div'>
                        <input
                            type='email'
                            placeholder='Your Email Address'
                            className='form-input'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </div>

                    <div className='form-input-div'>
                        <input
                            type='text'
                            placeholder='Your Phone Number'
                            className='form-input'
                            onChange={(e) => setNumber(e.target.value)}
                            value={number}
                        />
                    </div>

                    <div className='form-input-div form-input-textarea'>
                        <textarea
                            placeholder='Your Message'
                            className='form-input'
                            onChange={(e) => setMessage(e.target.value)}
                            value={message}
                        />
                    </div>

                    <button className='btn btn-flex contact-btn stars' type='submit' style={{color:'white'}}>Submit</button>
                </form>
                <img src={assets.conta} alt='' style={{ width: '30%' }} />
            </div>
        </section>
    );
}

export default Contact;
