import React from 'react';
import { animateScroll } from 'react-scroll';
import { Link } from 'react-scroll';

const Navbartwo = () => {
  const scrollTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <header className='header'>
      <nav className='nav container'>
        <Link href='/' onClick={scrollTop} className='nav__logo'>
          <img src="https://i.ibb.co/bskpYYK/logo-1.png" alt='Logo' className='nav__logo-img' />
        </Link>
      </nav>
    </header>
  );
};

export default Navbartwo;
