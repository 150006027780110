
import seatwo from './Assets/seatwo.png'
import seaone from './Assets/seaone.png'
import seathree from './Assets/seathree.png'

export const links = [
    {
        name: 'Home',
        path: 'home',
    },
    {
        name: 'About',
        path: 'about',
    },
    {
        name: 'Features',
        path: 'features',
    },
  
    {
        name: 'DownloadApp',
        path: 'download',
    },
    {
        name: 'Contact',
        path: 'contact',
    },
    
    
   
];

export const features = [
    {
      id: 1,
      title: 'Find the Perfect Spa Near You',
      image:  seathree,
      description: 'Looking for a spa close to home or work? Our location-based search feature allows you to discover spas in your vicinity effortlessly. Simply enter your desired location, and we’ll show you a curated list of the best spas nearby.',
      subheading: 'Benefits:',
      subcontent: 'Quickly find spas within a specific area. View spa locations on a map for easy navigation. Filter results based on your preferences and needs.'
    },
    {
      id: 2,
      title: 'Select the Right Professional for Your Needs',
      image: seatwo,
      description: 'Our app allows you to choose from a wide range of professionals based on the services they offer. Whether you need a skilled masseuse, a talented hairstylist, or a top-notch beautician, you can easily find the right professional for your specific requirements.',
      subheading: 'Benefits:',
      subcontent: 'Find professionals who specialize in the services you need. View professional profiles.'
    },
    {
      id: 3,
      title: 'Effortless Booking at Your Fingertips',
      image: seaone,
      description: 'Booking spa services has never been easier. With our intuitive booking system, you can schedule your appointments in just a few taps. Browse available time slots, select your preferred date and time, and confirm your booking instantly.',
      subheading: 'Benefits:',
      subcontent: 'Check and book available time slots in real-time. Receive immediate booking confirmations to your device.'
    },
  ];
  


export const about = [

    {
        id: 1,
        title: 'Our Mission',
        description :'Our mission is to transform the way you book and experience salon and spa services. We aim to provide an effortless and reliable booking experience that saves you time and ensures you receive top-quality services without the hassle of long waits and uncertainties.'

    },
    {
        id: 2,
        title: 'What We Offer',
        description:'Effortless Booking: Our user-friendly app allows you to browse and book a wide range of salon and spa services with just a few taps. From relaxing spa treatments to stylish haircuts, finding and booking your desired service has never been easier.'
        
    },
    {
        id: 3,
        title:'Transparency',
        description:'We prioritize transparency for both users and service providers. Our app provides real-time updates on availability and pricing, allowing you to make informed decisions. Read reviews, compare services, and choose the best option.'

    },
    {
        id: 4,
        title:'Our Vision',
        description:'We envision a world where booking salon and spa services is as easy and enjoyable as the services themselves. By connecting users with trusted professionals and offering a seamless booking experience, we strive to make self-care and grooming accessible to everyone.'
    }

]