import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import DownloadApp from './Components/DownloadApp/DownloadApp';
import Footer from './Components/Footer/Footer';
import Contact from './Components/Contact/Contact';
import Gototop from './Components/Gototop';
import RefundPolicy from './Components/Refund/RefundPolicy';
import Features from './Components/Feauters/Features';
import Terms from './Components/Terms and Conditions/Terms';
import Delete from './Components/Delete';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Privacy from './Components/Privacy/Privacy';

function App() {
  return (
    <Router>
      <div className='app'>
       
        <Routes>
          <Route path="/" element={<>
            <Navbar />
            <Home />
            <About />
            <Features />
            <DownloadApp />
            <Contact />
          </>} />
          <Route path="/refund" element={<RefundPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/delete-account" element={<Delete />} />
        </Routes>
        <Footer />
        <Gototop />
      </div>
    </Router>
  );
}

export default App;
