import React from 'react';
import Navbartwo from '../Navbartwo';
// import './Refund.css'


const Terms = () => {
  return (
    <>
    <Navbartwo/>
    <div className="terms-container">
    
    <div className='xyz'>
    <h1>Terms and Conditions</h1>
    </div>
    <div className='refundcontent' >
    <div className='contents'style={{width:'90%', height:"100%" , margin:"auto"}}>
    <p>Welcome to EaseSeat! By accessing and using our online booking app, you agree to be bound by these Terms and Conditions. Please read them carefully before using the app.</p>
    <h2 style={{color:"black"}}>1. Services Offered:</h2>
    <p>EaseSeat provides a platform for users to book appointments at spas and salons and enables spa and salon owners to list their services and professionals.</p>
    <p>We facilitate the online booking process but are not responsible for the actual provision of spa or salon services.</p>
    <p>Spas and salons are responsible for fulfilling bookings, maintaining service accuracy, and adhering to applicable regulations.</p>
    <h2 style={{color:"black"}}>2. Bookings and Cancellations:</h2>
    <p>Users can book appointments directly through the EaseSeat app.</p>
    <p>Users may cancel their bookings in accordance with the cancellation policy set by the respective spa or salon.</p>
    <p>Spas and salons have the right to cancel bookings at their discretion for reasons such as staff unavailability, operational issues, or suspected fraudulent activity.</p>
    <p>Users will receive a notification and a full refund within 3-5 working days if a booking is canceled by the spa or salon.</p>
    <h2 style={{color:"black"}}>3. Payment and Pricing:</h2>
    <p>Users will pay for their bookings directly through the EaseSeat app using their selected payment method or directly to the spa/salon via cash.</p>
    <p>The booking price includes the service cost, taxes, and any applicable fees set by the spa or salon.</p>
    <p>EaseSeat may charge additional service fees based on your location or booking type.</p>
    <h2 style={{color:"black"}}>4. User Accounts and Reviews:</h2>
    <p>Users need to create an account to save their preferences.</p>
    <p>Users are responsible for maintaining the confidentiality of their account information.</p>
    <p>Users can leave reviews about spas, salons, and their services but must refrain from posting malicious or defamatory content.</p>
    <h2 style={{color:"black"}}>5. Services and Responsibility:</h2>
    <p>Spas and salons are responsible for providing the booked services within the estimated timeframe.</p>
    <p>EaseSeat is not responsible for any delays or issues that occur during the provision of services.</p>
    <p>In case of service issues, users should contact the spa or salon directly through the app for assistance.</p>
    <h2 style={{color:"black"}}>6. Refunds and Returns:</h2>
    <p>Users may be eligible for a full or partial refund for incorrect bookings, poor service, excessive delays, or unsatisfactory experiences.</p>
    <p>Users should contact the spa or salon directly within 24 hours of the scheduled appointment to request a refund.</p>
    <p>EaseSeat may facilitate the refund process but is not responsible for the final decision.</p>
    <p>Note: The refund related to the services totally depends on the spas/salons.</p>
    <h2 style={{color:"black"}}>7. Disclaimer:</h2>
    <p>EaseSeat does not warrant the accuracy or completeness of service descriptions or spa/salon information.</p>
    <p>We strive to provide a reliable service, but technical issues or outages may occasionally occur.</p>
    <p>We are not responsible for any damages or losses resulting from your use of the app.</p>
    <h2 style={{color:"black"}}>8. Intellectual Property:</h2>
    <p>EaseSeat owns all intellectual property rights associated with the app and its content.</p>
    <p>Users may not use or reproduce any copyrighted material without our permission.</p>
    <h2 style={{color:"black"}}>9. Governing Law and Dispute Resolution:</h2>
    <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of India.</p>
    <h2 style={{color:"black"}}>10. Modification and Termination:</h2>
    <p>EaseSeat reserves the right to modify these Terms and Conditions at any time without prior notice.</p>
    <p>Users are responsible for reviewing these Terms and Conditions periodically for updates.</p>
    <p>We may terminate your access to the app at any time for violation of these Terms and Conditions.</p>
    <h2 style={{color:"black"}}>11. Contact Us:</h2>
    <p>If you have any questions or concerns about these Terms and Conditions, please contact us at sales@omnimindsconsulting.com.</p>
    <p>We appreciate your choice of EaseSeat and strive to provide the best booking experience possible!</p>
</div>
</div>
</div>
</>
  )
}

export default Terms