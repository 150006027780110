// src/components/DownloadApp/DownloadApp.js
import React from 'react';
import './Appdownload.css';
import { assets } from '../../Assets/Assets';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DownloadApp = () => {
  const handleClick = (platform) => {
    toast.info(`Coming Soon ${platform}`, {
      position: 'bottom-right'
    });
  };
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.easeseat.escustomer'

  return (
    <div className='app-download' id='download'>
      <p>For Better Experience Download <br /> EaseSeat App</p>
      <div className='app-download-platforms'>
        <img
          src={assets.play_store}
          alt='Download on Play Store'
          onClick={() => window.open(playStoreUrl, '_blank')}// Redirect to Play Store link
        className='clickable'
        style={{ cursor: 'pointer' }}  // Ensures the image is clickable
        />
        <img
          src={assets.app_store}
          alt='Download on App Store'
          onClick={() => handleClick('App Store')}
          className='clickable'
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default DownloadApp;
