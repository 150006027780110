import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createBrowserHistory } from 'history';


const browserHistory = createBrowserHistory();
const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  browserHistory.replace({pathname: path, hash: ''});
}

ReactDOM.render(
    // <React.StrictMode>
      <App />,
    // </React.StrictMode>,
    document.getElementById('root')
);
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//  <React.StrictMode>
//     <App />
//     </React.StrictMode>
  
// );

// If you're using a custom build setup, ensure publicPath is set correctly
// For example, in webpack:
// output: {
//   publicPath: '/your-app-path/' // Adjust as needed
// }
